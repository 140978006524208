<template>
  <b-col
    class="p-0"
    lg="12"
  >
    <b-card
      v-if="canViewThisAction('read', 'VendorPayment')"
      no-body
      class="mb-0"
    >
      <!-- <b-card-title class="mb-1 fs-18 weight-500">
        <feather-icon
          icon="ListIcon"
          class="mr-75"
          size="20"
        />{{ widgetName }} ({{ totalRows }})
      </b-card-title> -->

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-space_between mb-1 mb-md-0"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <a
                href="javascript:void(0)"
                class="nav-link nav-link-search"
                @click="showSearchBar = !showSearchBar"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="21"
                  class="mr-50"
                />
                <span class="align-middle">Search</span>
              </a>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search..."
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="searchQuery = ''; showSearchBar = !showSearchBar"
                  />
                </b-input-group-append>
              </b-input-group>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refVendorPaymentTable"
        class="position-relative event_list"
        :items="tableItems"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :current-page.sync="currentPage"
        :per-page="perPage"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :sort-compare="sortCompare"
      >
        <template #cell(invoiceID)="data">
          <div class="text-nowrap">
            <div v-if="canViewThisAction('show', 'VendorPayment')">
              <b-button
                variant="link"
                class="p-0"
                :to="{ name: 'purchasing-vendor-payment-single-view', params: { id: data.item._id } }"
              >
                {{ data.item.invoiceID }}
              </b-button>
            </div>
            <div v-else>
              <span>{{ data.item.invoiceID }}</span>
            </div>
          </div>
        </template>

        <template #cell(prCaseID)="data">
          <div class="text-nowrap">
            <div v-if="canViewThisAction('show', 'PurchaseRequest') || canViewThisAction('my-request-show', 'MyPurchaseRequest') || canViewThisAction('my-department-request-view', 'MyPurchaseRequest')">
              <b-button
                variant="link"
                class="p-0"
                :to="{ name: 'purchasing-purchase-request-show', params: { id: data.item.purchaseRequest._id }, query: { tab: 'invoice' } }"
              >
                {{ data.item.prCaseID }}
              </b-button>
            </div>
            <div v-else>
              <span>{{ data.item.prCaseID }}</span>
            </div>
            <span>{{ data.item.prID }}</span>
          </div>
        </template>
        <!-- Column: Category -->
        <template #cell(createdBy)="data">
          <div
            class=""
          >
            <span class="">{{ data.item.createdBy.name || '-' }}</span>
          </div>
          <span class="">{{ dateFormatWithTime(data.item.createdAt) }}</span>
        </template>
        <!-- Column: Status -->
        <template #cell(deptCheckStatus)="data">
          <div v-if="data.item.status == 'cancelled'">
            <b-badge
              variant="light-danger"
              class="text-capitalize"
            >
              Cancelled
            </b-badge>
          </div>
          <div v-else>
            <div>
              <b-badge
                :variant="`light-${resolveInvoiceStatusVariant(data.item.deptCheckStatus)}`"
                class="text-capitalize"
              >
                {{ data.item.deptCheckStatus }}
              </b-badge>
            </div>
            <span v-if="data.item.deptCheckDate">
              {{ dateFormatWithTime(data.item.deptCheckDate) }}
            </span>
            <span v-else-if="data.item.deptCheckStatus == 'pending'">
              {{ data.item.deptPendingSignatory.name }}
            </span>
          </div>
        </template>

        <template #cell(cpCheckStatus)="data">
          <div v-if="data.item.status == 'cancelled'">
            <b-badge
              variant="light-danger"
              class="text-capitalize"
            >
              Cancelled
            </b-badge>
          </div>
          <div v-else>
            <div>
              <b-badge
                :variant="`light-${resolveInvoiceStatusVariant(data.item.cpCheckStatus)}`"
                class="text-capitalize"
              >
                {{ data.item.cpCheckStatus }}
              </b-badge>
            </div>
            <span v-if="data.item.cpCheckDate">
              {{ dateFormatWithTime(data.item.cpCheckDate) }}
            </span>
            <span v-else-if="data.item.cpCheckStatus == 'pending'">
              {{ data.item.cpUser.name }}
            </span>
          </div>
        </template>
        <template #cell(paymentStatus)="data">
          <div v-if="data.item.paymentStatus || (data.item.deptCheckDate && data.item.cpCheckDate)">
            <b-badge
              :variant="`light-${resolveInvoiceStatusVariant(data.item.paymentStatus)}`"
              class="text-capitalize"
            >
              <span v-if="data.item.paymentStatus">{{ data.item.paymentStatus }}</span>
              <span v-else>To Process</span>
            </b-badge>
          </div>
          <div v-else>
            <span>-</span>
          </div>
          <span v-if="data.item.paymentStatus == 'Paid'">
            {{ dateFormatWithTime(data.item.processedDate) }}
          </span>
          <span v-else-if="data.item.paymentStatus == 'Cancelled'">
            {{ dateFormatWithTime(data.item.updatedAt) }}
          </span>
          <span v-else-if="data.item.financeRemarksStatus">
            {{ data.item.financeRemarksStatus }}
          </span>
        </template>
        <template #cell(totalPayable)="data">
          <div
            class="text-bold-black"
          >
            <span class="">$ {{ numberFormat(data.item.totalPayable) }}</span>
          </div>
          <span class="">{{ data.item.paymentMethod }}</span>
        </template>

        <template #cell(requestType)="data">
          <span class="">{{ data.item.purchaseRequest.requestType }}</span>
        </template>

        <template #cell(purpose)="data">
          <span class="">{{ data.item.purchaseRequest.purpose }}</span>
        </template>

        <template #head(freshness)="">
          <span class="">Freshness ({{ dateOption }})</span>
        </template>

        <template #cell(freshness)="data">
          <span class="text-bold-black">{{ getFreshness(data.item) }}</span>
        </template>
      </b-table>
    </b-card>

    <div
      class="mx-2 mb-2"
    >
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center pagination-content"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            align="center"
            prev-text="Prev"
            next-text="Next"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
              <span>Prev</span>
            </template>
            <template #next-text>
              <span>Next</span>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-col>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BButton, VBTooltip, BPagination, BBadge, BInputGroup, BInputGroupAppend, BFormInput,
} from 'bootstrap-vue'
import store from '@/store/index'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BButton,
    BPagination,
    BBadge,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    widgetName: {
      type: [String, null],
      default: '',
    },
    requestType: {
      type: [Array, null],
      default: () => [],
    },
    dateOption: {
      type: [String, null],
      default: '',
    },
    dateRangeOption: {
      type: [Array, null],
      default: () => [],
    },
    tableColumns: {
      type: [Array, null],
      default: () => [],
    },
    vendorPaymentsCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      user: store.state.auth.userData,
      render: false,
      showSearchBar: false,
      searchQuery: '',
      sortBy: 'stringID',
      sortDesc: false,
      isSortDirDesc: true,
      tableItems: [],
      currentPage: 1,
      totalRows: 0,
      perPage: 10,
    }
  },
  computed: {
    dataMeta() {
      let totalPage = 1
      let localItems = 0
      if (this.totalRows) {
        totalPage = Math.ceil(this.totalRows / this.perPage)
        if (totalPage === this.currentPage) {
          localItems = this.totalRows - (this.perPage * (this.currentPage - 1))
        } else {
          localItems = 10
        }
      }
      return {
        from: this.perPage * (this.currentPage - 1) + (this.totalRows ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItems,
        of: this.totalRows,
      }
    },
  },

  watch: {
    searchQuery: {
      immediate: true,
      handler() {
        this.getData()
      },
    },

    $props: {
      handler() {
        this.getData()
      },
      deep: true,
      immediate: true,
    },
  },

  beforeMount() {
    this.getData()
  },

  methods: {
    canViewThisAction,
    getData() {
      const formData = new FormData()
      formData.append('search', this.searchQuery)
      formData.append('dateOption', this.dateOption)
      formData.append('sortBy', this.sortBy)
      formData.append('sortDesc', this.sortDesc)
      formData.append('dateRangeOption', JSON.stringify(this.dateRangeOption))
      formData.append('requestType', JSON.stringify(this.requestType))

      this.$http.post('user/purchasing/vendor-payments/department/find', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(response => {
          const { vendorPayments } = response.data
          this.tableItems = vendorPayments
          this.totalRows = vendorPayments.length
          this.$emit('update:vendorPaymentsCount', this.totalRows)
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    resolveInvoiceStatusVariant(status) {
      if (status === 'pending') return 'warning'
      if (status === 'approved') return 'success'
      if (status === 'reviewed') return 'success'
      if (status === 'checked') return 'success'
      if (status === 'Paid') return 'success'
      if (status === 'Pending') return 'warning'
      if (status === 'Processing') return 'info'
      if (status === 'Rejected') return 'danger'
      if (status === 'Cancelled') return 'danger'
      return 'primary'
    },
    getFreshness(item) {
      const timezone = process.env.VUE_APP_TIME_ZONE
      let date = ''
      if (this.dateOption === 'Checks by Dept/HQ') {
        date = item.deptCheckDate
      } else if (this.dateOption === 'Checks by CP') {
        date = item.cpCheckDate
      } else {
        date = item.createdAt
      }
      const appointmentDate = moment(date).utcOffset(timezone)
      const today = moment().utcOffset(timezone)

      const difference = appointmentDate.diff(today, 'days')

      if (difference === 0) return 'Today'
      if (difference === 1) return '1 day later'
      if (difference === -1) return '1 day ago'
      if (difference > 1) return `${difference} days later`
      if (difference < -1) return `${Math.abs(difference)} days ago`

      return ''
    },
    // eslint-disable-next-line consistent-return
    sortCompare(aRow, bRow, key) {
      if (key === 'freshness') {
        if (this.dateOption === 'Checks by Dept/HQ') {
          // eslint-disable-next-line no-nested-ternary
          return aRow.deptCheckDate < bRow.deptCheckDate ? -1 : aRow.deptCheckDate > bRow.deptCheckDate ? 1 : 0
        }
        if (this.dateOption === 'Checks by CP') {
          // eslint-disable-next-line no-nested-ternary
          return aRow.cpCheckDate < bRow.cpCheckDate ? -1 : aRow.cpCheckDate > bRow.cpCheckDate ? 1 : 0
        }

        // eslint-disable-next-line no-nested-ternary
        return aRow.createdAt < bRow.createdAt ? -1 : aRow.createdAt > bRow.createdAt ? 1 : 0
      }
    },
  },
}
</script>
